<template>
  <v-container
    class="pa-0"
    style="overflowY: auto; height: 100%; width: 100%"
    fluid
  >
    <v-progress-linear
      v-if="isLoading || isNotificationReportsDelayLoading"
      :indeterminate="true"
    />
    <div class="pa-3">
      <v-col>
        <v-row
          class="primary--text justify-center align-center mb-2"
        >
          <div
            class="subtitle-1 text-sm-h6 font-weight-bold"
          >
            {{
              $tc(
                'deconve.report.positiveNotifications',
                getPositiveNotifications,
                {n: getPositiveNotifications}
              )
            }}
          </div>
        </v-row>
        <v-row class="mb-1 align-end">
          <div class="mr-2">
            <span class="font-weight-medium caption text-sm-body-2">
              {{ $t('deconve.report.dateInterval') }}
            </span>
            <date-filter
              :start-date="startDate"
              :end-date="endDate"
              @change-start-date="changeStartDate"
              @change-end-date="changeEndDate"
            />
          </div>
          <div
            class="mr-2"
            style="flex: 1; maxWidth: 200px"
          >
            <span class="font-weight-medium caption text-sm-body-2">
              {{ $t('deconve.report.periods') }}
            </span>
            <input-options
              v-model="periodFilterSelected"
              :placeholder="$t('deconve.report.periods')"
              min-width="100px"
              :options="chartPeriodFilters"
            />
          </div>

          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                :disabled="isDownloadingReports"
                v-on="on"
              >
                <v-icon v-if="!isDownloadingReports">
                  {{ icons.mdiDownload }}
                </v-icon>

                <v-progress-circular
                  v-else
                  indeterminate
                  :size="20"
                  :width="2.5"
                  color="neutral"
                />
              </v-btn>

              <v-snackbar v-model="isToShowDownloadErrorMessage">
                <template v-slot:action="{ attrs }">
                  <v-icon
                    color="error"
                    v-bind="attrs"
                    @click="isToShowDownloadErrorMessage = false"
                  >
                    {{ icons.mdiClose }}
                  </v-icon>
                </template>
                <span>{{ $t('deconve.error.internalError') }}</span>
              </v-snackbar>
            </template>
            <v-list>
              <v-list-item
                @click="onDownloadReport('day')"
              >
                {{ $t('deconve.downloadCsvByDay') }}
              </v-list-item>
              <v-list-item
                @click="onDownloadReport('month')"
              >
                {{ $t('deconve.downloadCsvByMonth') }}
              </v-list-item>
              <v-list-item
                @click="onDownloadReport('video')"
              >
                {{ $t('deconve.downloadCsvByVideo') }}
              </v-list-item>
              <v-list-item
                @click="onDownloadReport('person')"
              >
                {{ $t('deconve.downloadCsvByPerson') }}
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu
            :close-on-content-click="false"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="hasFilterChanged? 'primary' : 'neutral'"
                :class="hasFilterChanged? 'primaryHighlight' : 'white'"
                v-bind="attrs"
                class="mx-2"
                v-on="on"
              >
                <v-icon>
                  {{ hasFilterChanged ? 'mdi-filter':'mdi-filter-outline' }}
                </v-icon>
              </v-btn>
            </template>

            <reports-filter
              v-model="filterOptions"
              :has-changed="hasFilterChanged"
              @change="onFilterChanged"
              @clear="clearFilters"
            />
          </v-menu>
        </v-row>
        <v-row class="pa-0">
          <v-col
            md="6"
          >
            <v-row class="mb-1 px-1">
              <v-card
                class="flex-column pa-3"
                elevation="0"
                style="flex: 1"
                outlined
              >
                <v-card-title>{{ $t('deconve.report.notificationsChart') }}</v-card-title>

                <bar-chart
                  :chart-data="chartData"
                  :options="chartOptions"
                  style="display: flex; width: 100%;"
                  :height="250"
                />
              </v-card>
            </v-row>
          </v-col>
          <v-col
            md="6"
          >
            <v-row class="mb-1 px-1">
              <v-card
                class="flex-column pa-3"
                elevation="0"
                style="flex: 1"
                outlined
              >
                <v-card-title>{{ $t('deconve.report.notificationRegistrationTime') }}</v-card-title>
                <v-card-subtitle>
                  {{ $t('deconve.report.notificationRegistrationTimeInfo') }}
                </v-card-subtitle>

                <bar-chart
                  :chart-data="notificationReportsDelayData"
                  :options="notificationReportsDelayChartOptions"
                  style="display: flex; width: 100%"
                  :height="229"
                />
              </v-card>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="6"
          >
            <v-row class="mb-1 px-1">
              <v-card
                class="flex-column pa-3"
                elevation="0"
                style="flex: 1"
                outlined
              >
                <v-card-title>
                  {{ $t('deconve.videos') }}
                </v-card-title>

                <div class="d-flex mb-2 align-center">
                  <input-text
                    v-model="videoSourcesSearch"
                    :placeholder="$t('deconve.videos')"
                    left-icon="mdi-magnify"
                  />
                  <square-button
                    :icon-name="showVideoChart ? 'mdi-table-of-contents' : 'mdi-chart-donut'"
                    icon-color="neutral"
                    outlined
                    menu-disabled
                    color="border"
                    content-class="ml-2"
                    @clicked="showVideoChart = !showVideoChart"
                  />
                </div>
                <v-data-table
                  v-model="videoSourceSelected"
                  :headers="videoSourceHeaders"
                  :items="videoSources"
                  single-select
                  :items-per-page="initialItemsPerPage"
                  sort-by="total"
                  :sort-desc="true"
                  item-key="name"
                  :loading="isVideoDataTableLoading"
                  :options.sync="videoDataTableOptions"
                  :server-items-length="totalNumberOfVideos"
                  height="240px"
                  dense
                  show-select
                  @update:items-per-page="updateVideoPerPage"
                >
                  <template
                    v-if="showVideoChart"
                    v-slot:top
                  >
                    <doughnut-chart
                      v-if="videoSources.length > 0"
                      :chart-data="videoChartData"
                      :options="doughnutChartOptions"
                      style="display: flex; width: 100%"
                      :height="320"
                    />
                    <div
                      v-else
                      class="d-flex align-center justify-center"
                    >
                      {{ $t('deconve.noResult') }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-row>
          </v-col>
          <v-col
            md="6"
          >
            <v-row class="mb-1 px-1">
              <v-card
                class="flex-column pa-3"
                style="flex: 1"
                elevation="0"
                outlined
              >
                <v-card-title>{{ $t('deconve.people') }}</v-card-title>

                <div class="d-flex mb-2 align-center">
                  <input-text
                    v-model="peopleSearch"
                    :placeholder="$t('deconve.people')"
                    class="mr-2"
                    left-icon="mdi-magnify"
                  />
                  <square-button
                    :icon-name="showPeopleChart ? 'mdi-table-of-contents' : 'mdi-chart-donut'"
                    icon-color="neutral"
                    outlined
                    menu-disabled
                    color="border"
                    content-class="ml-2"
                    @clicked="showPeopleChart = !showPeopleChart"
                  />
                </div>
                <v-data-table
                  v-model="personSelected"
                  :headers="peopleHeaders"
                  :items="people"
                  :items-per-page="initialItemsPerPage"
                  single-select
                  sort-by="total"
                  :sort-desc="true"
                  :options.sync="peopleDataTableOptions"
                  :loading="isPeopleDataTableLoading"
                  item-key="name"
                  height="240px"
                  :server-items-length="totalNumberOfPeople"
                  dense
                  show-select
                  @update:items-per-page="updatePeoplePerPage"
                >
                  <template
                    v-if="showPeopleChart"
                    v-slot:top
                  >
                    <doughnut-chart
                      v-if="people.length > 0"
                      :chart-data="peopleChartData"
                      :options="doughnutChartOptions"
                      style="display: flex; width: 100%"
                      :height="320"
                    />
                    <div
                      v-else
                      class="d-flex align-center justify-center"
                    >
                      {{ $t('deconve.noResult') }}
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </v-container>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import InputText from '@/components/InputText.vue';
import InputOptions from '@/components/InputOptions.vue';
import DateFilter from '@/components/DateFilter.vue';
import BarChart from '@/components/BarChart';
import DoughnutChart from '@/components/DoughnutChart';
import { getNotificationReports } from '@/api/report';
import SquareButton from '@/components/SquareButton.vue';
import moment from 'moment';
import chartColors from '@/utils/colors';

import { mdiDownload, mdiClose } from '@mdi/js';

import ReportsFilter from './ReportsFilter.vue';

export default {
  name: 'Reports',
  components: {
    BarChart,
    DoughnutChart,
    InputText,
    InputOptions,
    DateFilter,
    SquareButton,
    ReportsFilter,
  },
  data: () => ({
    icons: {
      mdiDownload,
      mdiClose,
    },
    isVideoDataTableLoading: false,
    isPeopleDataTableLoading: false,
    videoSourcesSearch: '',
    videoSources: [],
    videoSourceSelected: [],
    totalNumberOfVideos: 0,
    initialItemsPerPage: 5,
    peopleSearch: '',
    totalNumberOfPeople: 0,
    people: [],
    personSelected: [],
    showPeopleChart: false,
    showVideoChart: false,
    startDate: '',
    endDate: '',
    periodFilterSelected: 0,
    videoDataTableOptions: {},
    peopleDataTableOptions: {},
    videoChartData: {},
    peopleChartData: {},
    chartData: {},
    filterOptions: {
      noNotificationTags: false,
      notificationTags: [],
      noPeopleTags: false,
      peopleTags: [],
    },
    doughnutChartOptions: {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
      },
    },
    chartOptions: {
      legend: {
        display: true,
      },
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
      },
      scales: {
        xAxes: [{
          beginAtZero: true,
          stacked: true,
          gridLines: { display: false },
          ticks: {
            stepSize: 1,
          },
        }],
        yAxes: [{
          beginAtZero: true,
          suggestedMin: 0,
          stacked: true,
        }],
      },
    },
    notificationReportsDelayChartOptions: {
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            let label = data.datasets[tooltipItem.datasetIndex].label || '';

            if (label) {
              label += ': ';
            }

            label += `${tooltipItem.yLabel}%`;
            return label;
          },
        },
      },
      scales: {
        xAxes: [{
          beginAtZero: true,
          stacked: true,
          gridLines: { display: false },
          ticks: {
            stepSize: 1,
          },
        }],
        yAxes: [{
          beginAtZero: true,
          suggestedMin: 0,
          stacked: true,
          ticks: {
            callback(value) {
              return `${value}%`;
            },
          },
        }],
      },
    },
    datasets: [],
    labels: [],
    isToShowDownloadErrorMessage: false,
  }),
  computed: {
    ...mapGetters({
      notificationStatistics: 'faceid/notificationStatistics',
      notificationReports: 'faceid/notificationReports',
      notificationReportsDelay: 'faceid/notificationReportsDelay',
      notificationVideoSources: 'faceid/notificationVideoSources',
      isLoading: 'faceid/reportIsLoading',
      isNotificationReportsDelayLoading: 'faceid/isNotificationReportsDelayLoading',
      isDownloadingReports: 'faceid/isDownloadingReports',
      reportsDownloadError: 'faceid/reportsDownloadError',
    }),
    getReportsDownloadError() {
      return this.reportsDownloadError;
    },
    hasFilterChanged() {
      return Boolean(
        this.filterOptions.noNotificationTags
        || this.filterOptions.notificationTags.length > 0
        || this.filterOptions.noPeopleTags
        || this.filterOptions.peopleTags.length > 0,
      );
    },
    notificationReportsDelayData() {
      const { labels, datasets } = this.notificationReportsDelay;
      const [firstDataset] = datasets;

      const updateDataset = [];

      if (firstDataset) {
        const { data } = firstDataset;

        const { notificationDelayChartColor } = this.$vuetify.theme.themes.light;

        updateDataset.push({ data, backgroundColor: notificationDelayChartColor });
      }

      return { labels, datasets: updateDataset };
    },
    videoSourceHeaders() {
      return [
        {
          text: this.$t('deconve.videos'),
          align: 'start',
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('deconve.report.filter.positives'),
          value: 'positive',
        },
        {
          text: this.$t('deconve.report.filter.negatives'),
          value: 'negative',
        },
        {
          text: this.$t('deconve.report.filter.unreviewed'),
          value: 'unreviewed',
        },
        {
          text: this.$t('deconve.report.filter.total'),
          value: 'total',
        },
      ];
    },
    peopleHeaders() {
      return [
        {
          text: this.$t('deconve.report.personName'),
          align: 'start',
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('deconve.report.filter.positives'),
          value: 'positive',
        },
        {
          text: this.$t('deconve.report.filter.negatives'),
          value: 'negative',
        },
        {
          text: this.$t('deconve.report.filter.unreviewed'),
          value: 'unreviewed',
        },
        {
          text: this.$t('deconve.report.filter.total'),
          value: 'total',
        },
      ];
    },
    periodFilters() {
      return {
        byMonth: this.$t('deconve.report.filter.period.byMonth'),
        byDay: this.$t('deconve.report.filter.period.byDay'),
        byHour: this.$t('deconve.report.filter.period.byHour'),
      };
    },
    chartPeriodFilters() {
      return [
        this.periodFilters.byDay,
        this.periodFilters.byMonth,
        this.periodFilters.byHour,
      ];
    },
    chartPeriodFormatters() {
      return {
        [this.periodFilters.byMonth]: { formatter: 'MMMM/YYYY', type: 'month' },
        [this.periodFilters.byDay]: { formatter: 'DD/MM/YYYY', type: 'day' },
        [this.periodFilters.byHour]: { formatter: 'HH:mm', type: 'hours' },
      };
    },
    groupByTypes() {
      return {
        [this.periodFilters.byMonth]: 'month',
        [this.periodFilters.byDay]: 'day',
        [this.periodFilters.byHour]: 'hour',
      };
    },
    getPositiveNotifications() {
      return this.notificationStatistics?.positive || 0;
    },
  },

  watch: {
    videoSourcesSearch: 'organizeVideoSources',
    peopleSearch: 'organizePeople',
    videoDataTableOptions() {
      const { sortBy, sortDesc } = this.videoDataTableOptions;

      if (!sortBy[0]) {
        this.videoDataTableOptions.sortBy = ['total'];
      }

      if (sortDesc[0] === undefined) {
        this.videoDataTableOptions.sortDesc = [false];
      }

      this.organizeVideoSources();
    },
    peopleDataTableOptions() {
      const { sortBy, sortDesc } = this.peopleDataTableOptions;

      if (!sortBy[0]) {
        this.peopleDataTableOptions.sortBy = ['total'];
      }

      if (sortDesc[0] === undefined) {
        this.peopleDataTableOptions.sortDesc = [false];
      }

      this.organizePeople();
    },
    periodFilterSelected() {
      this.handleFillChartLabels();
      this.addChartData();
    },
    videoSourceSelected() {
      this.organizePeople();
      this.addChartData();
    },
    personSelected() {
      this.organizeVideoSources();
      this.addChartData();
    },
    getReportsDownloadError(newValue) {
      if (newValue) {
        this.isToShowDownloadErrorMessage = true;
      }
    },
  },
  created() {
    this.$moment.locale(this.$i18n.locale);
    this.getNotificationStatistics();
    this.initDateRange();

    this.getNotificationVideoSources().then(() => {
      this.handleShowNotificationsInChart();
    }).catch(() => {
      this.handleShowNotificationsInChart();
    });
  },
  methods: {
    ...mapActions({
      downloadNotificationReports: 'faceid/downloadNotificationReports',
      fetchPerson: 'faceid/fetchPerson',
      getNotificationStatistics: 'faceid/getNotificationStatistics',
      getNotificationReports: 'faceid/getNotificationReports',
      getNotificationVideoSources: 'faceid/getNotificationVideoSources',
      getNotificationReportsByNotificationDelay: 'faceid/getNotificationReportsByNotificationDelay',
    }),
    clearFilters() {
      this.filterOptions = {
        noNotificationTags: false,
        notificationTags: [],
        noPeopleTags: false,
        peopleTags: [],
      };

      this.onFilterChanged();
    },
    defineSortOrder(isSortDesc) {
      if (isSortDesc !== undefined) {
        return isSortDesc ? 'descending' : 'ascending';
      }

      return isSortDesc;
    },
    updatePeoplePerPage() {
      this.peopleDataTableOptions.page = 1;
    },
    updateVideoPerPage() {
      this.videoDataTableOptions.page = 1;
    },
    handleSelectVideoSource(index) {
      if (this.videoSources[index].selected) {
        this.videoSources[index].selected = false;
      } else {
        this.videoSources[index].selected = true;
      }
    },
    initDateRange() {
      const oneMonthBefore = this.$moment().add(-1, 'months');

      this.startDate = oneMonthBefore.startOf('day').toISOString();
      this.endDate = this.$moment().endOf('day').toISOString();
    },
    changeStartDate(newDate) {
      this.startDate = this.$moment(newDate).startOf('day').toISOString();
      this.handleShowNotificationsInChart();
    },
    changeEndDate(newDate) {
      this.endDate = this.$moment(newDate).endOf('day').toISOString();
      this.handleShowNotificationsInChart();
    },
    organizeVideoSources() {
      if (this.notificationVideoSources.length === 0) return;

      const personId = this.personSelected[0]?.id || '';
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = this.videoDataTableOptions;

      const sortByData = sortBy?.length > 0 ? sortBy[0] : undefined;
      const sortDescData = sortDesc?.length > 0 ? sortDesc[0] : undefined;

      this.isVideoDataTableLoading = true;

      getNotificationReports({
        personId,
        sortBy: sortByData,
        search: this.videoSourcesSearch,
        sortOrder: this.defineSortOrder(sortDescData),
        startDate: this.startDate,
        endDate: this.endDate,
        groupBy: 'video',
        limit: itemsPerPage,
        skip: (page - 1) * itemsPerPage,
        notificationTags: this.filterOptions.notificationTags,
        noNotificationTags: this.filterOptions.noNotificationTags,
        peopleTags: this.filterOptions.peopleTags,
        noPeopleTags: this.filterOptions.noPeopleTags,
      }).then((data) => {
        this.isVideoDataTableLoading = false;
        this.totalNumberOfVideos = data.total;
        this.videoSources = [];

        const labels = [];
        const dataset = [];

        data.items.forEach((report) => {
          const notificationVideoSource = this.notificationVideoSources.find(
            (videoSource) => videoSource.id === report.video.id,
          );
          const reportVideoSource = {
            id: report.video.id,
            name: notificationVideoSource.name,
            total: report.total,
            positive: report.positive,
            negative: report.negative,
            unreviewed: report.unreviewed,
          };

          this.videoSources.push(reportVideoSource);
          labels.push(notificationVideoSource.name);
          dataset.push(report[sortByData]);
        });

        this.videoChartData = {
          labels,
          datasets: [
            {
              label: 'A',
              data: dataset,
              backgroundColor: chartColors(dataset.length),
            },
          ],
        };
      });
    },
    organizePeople() {
      const videoId = this.videoSourceSelected[0]?.id || '';
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = this.peopleDataTableOptions;

      const sortByData = sortBy?.length > 0 ? sortBy[0] : undefined;
      const sortDescData = sortDesc?.length > 0 ? sortDesc[0] : undefined;

      this.isPeopleDataTableLoading = true;

      getNotificationReports({
        videoId,
        sortBy: sortByData,
        search: this.peopleSearch,
        sortOrder: this.defineSortOrder(sortDescData),
        startDate: this.startDate,
        endDate: this.endDate,
        groupBy: 'person',
        limit: itemsPerPage,
        skip: (page - 1) * itemsPerPage,
        notificationTags: this.filterOptions.notificationTags,
        noNotificationTags: this.filterOptions.noNotificationTags,
        peopleTags: this.filterOptions.peopleTags,
        noPeopleTags: this.filterOptions.noPeopleTags,
      }).then((data) => {
        this.isPeopleDataTableLoading = false;
        this.totalNumberOfPeople = data.total;

        const labels = [];
        const dataset = [];
        const peoplePromises = [];

        data.items.forEach((report) => {
          peoplePromises.push(
            this.fetchPerson(report.person.id)
              .then((person) => ({ person, report }))
              .catch(() => ({ person: report.person, report })),
          );
        });
        Promise.all(peoplePromises).then((people) => {
          this.people = [];
          people.forEach(({ person, report }) => {
            this.people.push({
              id: report.person.id,
              name: person.name || person.id,
              positive: report.positive,
              negative: report.negative,
              unreviewed: report.unreviewed,
              total: report.total,
            });
            labels.push(person.name || person.id);
            dataset.push(String(report[sortByData]));
          });
          this.peopleChartData = {
            labels,
            datasets: [
              {
                label: 'B',
                data: dataset,
                backgroundColor: chartColors(dataset.length),
              },
            ],
          };
        });
      });
    },
    handleShowNotificationsInChart() {
      this.handleFillChartLabels();
      this.addChartData();
      this.organizePeople();
      this.organizeVideoSources();
    },
    onFilterChanged() {
      this.organizePeople();
      this.organizeVideoSources();
      this.addChartData();
    },
    addChartData() {
      this.datasets = [];
      this.chartData = {
        labels: [],
        datasets: [],
      };
      const personId = this.personSelected[0]?.id || '';
      const videoId = this.videoSourceSelected[0]?.id || '';
      const periodSelected = this.chartPeriodFilters[this.periodFilterSelected];
      const periodFormatter = this.chartPeriodFormatters[periodSelected].formatter;
      const periodType = this.chartPeriodFormatters[periodSelected].type;
      const groupBy = this.groupByTypes[periodSelected];

      const queryOptions = {
        personId,
        videoId,
        startDate: this.startDate,
        endDate: this.endDate,
        notificationTags: this.filterOptions.notificationTags,
        noNotificationTags: this.filterOptions.noNotificationTags,
        peopleTags: this.filterOptions.peopleTags,
        noPeopleTags: this.filterOptions.noPeopleTags,
      };

      this.getNotificationReportsByNotificationDelay(queryOptions);

      queryOptions.groupBy = groupBy;
      this.getNotificationReports(queryOptions).then(() => {
        this.datasets = this.labels.map((label) => ({ ...label }));

        this.notificationReports.forEach((report) => {
          const {
            positive,
            negative,
            unreviewed,
            local_time_zone: localTimeZone,
            created_at: createdAt,
          } = report;

          const notificationDate = moment(createdAt);

          if (periodType === 'hours') {
            const notificationHour = notificationDate.utcOffset(localTimeZone).hours();

            const labelHourIndex = this.datasets.findIndex((data) => {
              if ((periodSelected === this.periodFilters.byHour)
                    && (notificationHour === new Date(data.label).getHours())) {
                return true;
              }

              return false;
            });
            const labelHourExists = labelHourIndex !== -1;

            if (labelHourExists) {
              this.datasets[labelHourIndex].positives += positive;
              this.datasets[labelHourIndex].negatives += negative;
              this.datasets[labelHourIndex].unreviewed += unreviewed;
            }
          } else {
            const notificationDateWithoutUTC = notificationDate.subtract(localTimeZone, 'hours');

            const labelDateIndex = this.datasets.findIndex((data) => {
              if (this.$moment(data.label).isSame(
                notificationDateWithoutUTC,
                periodType,
              )) {
                return true;
              }

              return false;
            });
            const labelDateExists = labelDateIndex !== -1;

            if (labelDateExists) {
              this.datasets[labelDateIndex].positives += positive;
              this.datasets[labelDateIndex].negatives += negative;
              this.datasets[labelDateIndex].unreviewed += unreviewed;
            }
          }
        });

        const labels = this.datasets.map((data) => (
          this.$moment(data.label)
            .format(
              periodFormatter,
            )
        ));
        const positives = this.datasets.map((datasets) => datasets.positives);
        const negatives = this.datasets.map((datasets) => datasets.negatives);
        const unreviewed = this.datasets.map((datasets) => datasets.unreviewed);
        const positivesTotal = positives.reduce((a, b) => (a + b), 0);
        const negativesTotal = negatives.reduce((a, b) => (a + b), 0);
        const unreviewedTotal = unreviewed.reduce((a, b) => (a + b), 0);

        this.chartData = {
          labels,
          datasets: [
            {
              label: `${positivesTotal} ${this.$t('deconve.report.filter.positives')}`,
              data: positives,
              backgroundColor: this.$vuetify.theme.themes.light.positive,
            }, {
              label: `${negativesTotal} ${this.$t('deconve.report.filter.negatives')}`,
              data: negatives,
              backgroundColor: this.$vuetify.theme.themes.light.negative,
            }, {
              label: `${unreviewedTotal} ${this.$t('deconve.report.filter.unreviewed')}`,
              data: unreviewed,
              backgroundColor: this.$vuetify.theme.themes.light.unreviewed,
            },
          ],
        };
      });
    },
    monthDiff(dateFrom, dateTo) {
      dateFrom.setDate(dateFrom.getDate() + 1);
      dateTo.setDate(dateTo.getDate() + 1);
      const years = dateTo.getFullYear() - dateFrom.getFullYear();
      const months = dateTo.getMonth() - dateFrom.getMonth();

      return 12 * years + months;
    },
    fillChartByMonths(startDateLimit, endDateLimit) {
      const months = this.monthDiff(startDateLimit, endDateLimit);

      for (let index = 0; index <= months; index += 1) {
        const startDateMonth = new Date(startDateLimit);

        const newDate = this.$moment(startDateMonth).add(index, 'months');

        this.labels.push({
          label: newDate,
          positives: 0,
          negatives: 0,
          unreviewed: 0,
        });
      }
    },
    fillChartByDays(startDateLimit, endDateLimit) {
      const daysDiff = endDateLimit.getTime() - startDateLimit.getTime();
      const days = Math.ceil(daysDiff / (1000 * 3600 * 24));

      for (let index = 0; index < days; index += 1) {
        const newDate = new Date(startDateLimit);
        const startDateDay = newDate.getDate();

        newDate.setDate(startDateDay + index);
        this.labels.push({
          label: newDate,
          positives: 0,
          negatives: 0,
          unreviewed: 0,
        });
      }
    },
    fillChartByHours() {
      const hours = 23;

      for (let index = 0; index <= hours; index += 1) {
        const newHour = new Date().setHours(index, 0, 0, 0);

        this.labels.push({
          label: newHour,
          positives: 0,
          negatives: 0,
          unreviewed: 0,
        });
      }
    },
    handleFillChartLabels() {
      this.labels = [];
      const periodSelected = this.chartPeriodFilters[this.periodFilterSelected];

      const startDateLimit = new Date(this.startDate);
      const endDateLimit = new Date(this.endDate);

      const isSortedByDay = periodSelected === this.periodFilters.byDay;
      const isSortedByMonth = periodSelected === this.periodFilters.byMonth;
      const isSortedByHour = periodSelected === this.periodFilters.byHour;

      if (isSortedByMonth) {
        this.fillChartByMonths(startDateLimit, endDateLimit);
      } else if (isSortedByDay) {
        this.fillChartByDays(startDateLimit, endDateLimit);
      } else if (isSortedByHour) {
        this.fillChartByHours();
      }
    },
    onDownloadReport(groupBy) {
      this.isVideoDataTableLoading = true;

      const personId = this.personSelected[0]?.id || '';
      const videoId = this.videoSourceSelected[0]?.id || '';

      const {
        sortBy,
        sortDesc,
      } = this.videoDataTableOptions;

      const sortByData = sortBy?.length > 0 ? sortBy[0] : undefined;
      const sortDescData = sortDesc?.length > 0 ? sortDesc[0] : undefined;

      this.downloadNotificationReports({
        personId,
        videoId,
        sortBy: sortByData,
        search: this.videoSourcesSearch,
        sortOrder: this.defineSortOrder(sortDescData),
        startDate: this.startDate,
        endDate: this.endDate,
        groupBy,
        notificationTags: this.filterOptions.notificationTags,
        noNotificationTags: this.filterOptions.noNotificationTags,
        peopleTags: this.filterOptions.peopleTags,
        noPeopleTags: this.filterOptions.noPeopleTags,
        formatType: 'csv',
        locale: this.$i18n.locale,
      }).then(() => {
        this.isVideoDataTableLoading = false;
      }).catch(() => {
        this.isVideoDataTableLoading = false;
      });
    },
  },
};
</script>
